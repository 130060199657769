<template>
  <div>Timeline</div>
</template>

<script>
export default {
  components: {},
  name: 'Teams-Timeline',

  // mounted() {},
  computed: {},
  methods: {}
};
</script>

<style></style>
